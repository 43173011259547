import { useFormContext } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { TextField, Select, Title, Checkbox } from "components";
import { Container, FormLayout, FormLayoutInline, Text } from "./styles";
import { useEffect, useState } from "react";
import _isEmpty from "lodash/isEmpty";
import { IconButton, Alert } from "@mui/material";
import { EyeIcon, EyeSlashIcon } from "assets";
import { useGlobalData } from "hooks";
import { useGetConsultant } from "features/connection-form/api";

const nacionalidade = [
  { value: "Brasileira(o)", label: "Brasileira(o)" },
  { value: "Estrangeira(o)", label: "Estrangeira(o)" },
];

const estadoCivis = [
  {
    label: "Casado(a)",
    value: "Casado(a)",
  },
  {
    label: "Solteiro(a)",
    value: "Solteiro(a)",
  },
  {
    label: "Viúvo(a)",
    value: "Viúvo(a)",
  },
  {
    label: "União Estável",
    value: "União Estável",
  },
  {
    label: "Divorciado(a)",
    value: "Divorciado(a)",
  },
];

const ExcludedAccentsRgx = /^[a-zA-Z0-9@._-]*$/;

export function PersonalInfoForm() {
  const { accountType, customer } = useGlobalData();
  const [showFieldPassword, setShowFieldPassword] = useState<boolean>(false);
  const [showWarningBanner, setShowWarningBanner] = useState<boolean>(false);
  const { watch, setError, clearErrors, trigger, reset, getValues, formState } =
    useFormContext();
  const [
    email,
    emailConfirm,
    needsPowerCompanyPassword,
    powerSupplier,
    stateOfConsumption,
  ] = watch([
    "email",
    "emailConfirm",
    "needPassword",
    "fornecedora",
    "ufconsumo",
  ]);
  const [searchParams] = useSearchParams();
  const sponsorId = searchParams.get("id") || "";
  const { data: consultantData } = useGetConsultant(sponsorId, {
    enabled: !!sponsorId,
  });
  const errors = formState.errors;

  useEffect(() => {
    if (customer) {
      reset({
        ...getValues(),
        nomeChecked: true,
        dtnascChecked: true,
        rgChecked: true,
        emissorChecked: true,
      });
    }
  }, []);

  useEffect(() => {
    if (!_isEmpty(email) && consultantData?.email === email)
      return setError("email", {
        message: "Não pode ser o email do licenciado",
      });

    if (!_isEmpty(email) && email !== emailConfirm)
      return setError("emailConfirm", {
        message: "Email diferente do fornecido",
      });

    clearErrors("emailConfirm");
  }, [email, emailConfirm]);

  useEffect(() => {
    if (
      needsPowerCompanyPassword &&
      powerSupplier === "FARO" &&
      stateOfConsumption === "TO" &&
      (errors.logindistribuidora || errors.senhadistribuidora)
    ) {
      setShowWarningBanner(true);
    }
  }, [
    errors.logindistribuidora,
    errors.senhadistribuidora,
    needsPowerCompanyPassword,
    powerSupplier,
    stateOfConsumption,
  ]);

  const handleCheck = async ({ name }: { name: string; value: boolean }) => {
    const fieldName = name.replace("Checked", "");
    await trigger(fieldName);
  };

  return (
    <Container>
      <Title>Insira os seus dados pessoais</Title>

      <Container>
        <FormLayout>
          <TextField
            name="cpf_cnpj"
            label="CPF"
            mask="000.000.000-00"
            required
            disabled={accountType === "PF"}
          />
        </FormLayout>

        <FormLayout>
          <TextField name="nome" label="Nome completo" required />
          <Checkbox name="nomeChecked" onChange={handleCheck} />
        </FormLayout>

        <FormLayout>
          <TextField
            name="dtnasc"
            label="Data de nascimento"
            mask="00/00/0000"
            required
          />
          <Checkbox name="dtnascChecked" onChange={handleCheck} />
        </FormLayout>

        <FormLayoutInline>
          <FormLayout>
            <TextField name="rg" label="RG" required />
            <Checkbox name="rgChecked" onChange={handleCheck} />
          </FormLayout>
          <FormLayout>
            <TextField name="emissor" label="Órgão emissor" required />
            <Checkbox name="emissorChecked" onChange={handleCheck} />
          </FormLayout>
        </FormLayoutInline>

        <TextField
          name="email"
          label="E-mail"
          type="email"
          mask={ExcludedAccentsRgx}
          required
        />

        <TextField
          name="emailConfirm"
          label="Confirme seu E-mail"
          mask={ExcludedAccentsRgx}
          onPaste={(e) => e.preventDefault()}
          required
        />
        <TextField
          name="celular"
          label="Celular"
          mask="(00) 00000-0000"
          required
        />
        <Select
          options={nacionalidade}
          name="nacionalidade"
          label="Nacionalidade"
          defaultValue={"Brasileira(o)"}
          required
        />
        <TextField name="profissao" label="Profissão" required />
        <Select
          options={estadoCivis}
          name="estadocivil"
          label="Estado Civil"
          required
        />
        {needsPowerCompanyPassword && (
          <>
            <Text>Digite seu login e senha da distribuidora:</Text>
            {showWarningBanner && (
              <Alert
                variant="standard"
                severity="warning"
                onClose={() => setShowWarningBanner(false)}
              >
                É necessário que o cliente cadastre o e-mail{" "}
                <span style={{ fontFamily: "Nexa Bold" }}>
                  clientes.eto@guaraenergia.com
                </span>{" "}
                no portal da Energisa.
              </Alert>
            )}
            <TextField name="logindistribuidora" label="Usuário" />
            <TextField
              name="senhadistribuidora"
              label="Senha"
              endAdornment={
                <IconButton
                  onClick={() =>
                    setShowFieldPassword((prevState) => !prevState)
                  }
                >
                  {showFieldPassword ? <EyeSlashIcon /> : <EyeIcon />}
                </IconButton>
              }
              type={showFieldPassword ? "text" : "password"}
            />
          </>
        )}
      </Container>
    </Container>
  );
}
