import { getFileExtensionFromBase64 } from "utils";
import { ConfigOption, Option, FormData } from "./ConnectionForm.types";

const findState = (option: ConfigOption) => (state: Option) =>
  state.value === option.uf;
const findCompany = (option: ConfigOption) => (company: Option) =>
  company.value === option.concessionaria;

const generateOption = (
  data: any, // convert to a generic type uisng T
  optionPaths: Option
) => ({
  label: data[optionPaths.label],
  value: data[optionPaths.value],
});

const makeGeneratePowerCompaniesOptions =
  (options: ConfigOption[]) => (state: string) => {
    if (!options) return [];
    const filteredOptions = options.filter((option) => option.uf === state && option.ativo);
    return filteredOptions.reduce((acc, option) => {
      if (acc.find(findCompany(option))) return acc;

      const powerCompanyOption = generateOption(option, {
        label: "concessionaria_label",
        value: "concessionaria",
      });

      return [...acc, { ...powerCompanyOption }];
    }, [] as Option[]);
  };

const makeGenerateRangeByStateAndCompany =
  (options: ConfigOption[]) => (state: string, company: string) => {
    if (!options) return [];
    const filteredOptionsByState = options.filter(
      (option) => option.uf === state
    );
    const filteredOptionsByCompany = filteredOptionsByState.filter(
      (option) => option.concessionaria === company && option.ativo
    );
    return filteredOptionsByCompany;
  };

const makeGenerateStateOptions = (options: ConfigOption[]) => () => {
  if (!options) return [];
  return options.reduce((acc, option) => {
    if (acc.find(findState(option))) return acc;

    const stateOption = generateOption(option, {
      label: "uflabel",
      value: "uf",
    });

    return [...acc, stateOption];
  }, [] as Option[]);
};

const makeGenerateDiscountsByCompany = (options: ConfigOption[]) => {
  return (
    state: string,
    powerCompany: string,
    averageConsumption: number,
    needManualAnalysis: boolean,
    customer: Record<string, string>
  ): Array<{ label: string; value: number }> => {
    if (!Array.isArray(options) || options.length === 0) {
      return [];
    }

    const filterByStateAndProvider = (
      state: string,
      provider: string,
      isActive: boolean
    ): ConfigOption[] =>
      options.filter(
        (option) =>
          option.uf === state &&
          option.fornecedora === provider &&
          option.ativo === isActive
      );

    const filterByConcessionaireAndProvider = (
      state: string,
      concessionaire: string,
      provider: string
    ): ConfigOption[] =>
      options.filter(
        (option) =>
          option.uf === state &&
          option.concessionaria === concessionaire &&
          option.fornecedora === provider
      );

    const filterByConcessionaire = (
      state: string,
      concessionaire: string,
      isActive: boolean
    ): ConfigOption[] =>
      options.filter(
        (option) =>
          option.uf === state &&
          option.concessionaria === concessionaire &&
          option.ativo === isActive
      );

    const isCustomerProvider = (provider: string): boolean =>
      customer?.fornecedora === provider;

    const filterByConsumption = (
      filteredOptions: ConfigOption[]
    ): ConfigOption[] =>
      filteredOptions.filter(
        (option) =>
          (option.min === 0 || averageConsumption >= option.min) &&
          (option.max === 0 || averageConsumption <= option.max)
      );

    const getFilteredOptions = (): ConfigOption[] => {
      if (needManualAnalysis) {
        return filterByStateAndProvider(state, "GV", false);
      }

      if (isCustomerProvider("GV")) {
        return filterByConcessionaireAndProvider(state, powerCompany, "GV");
      }

      return filterByConcessionaire(state, powerCompany, true);
    };

    const parseDiscounts = (discountString: string): Array<{ label: string; value: number }> =>
      discountString.split(",").map((discount) => {
        const trimmedDiscount = discount.trim();
        return {
          label: `${trimmedDiscount}%`,
          value: Number(trimmedDiscount),
        };
      });

    const filteredOptions = getFilteredOptions();
    const validOptions = filterByConsumption(filteredOptions);
    const selectedOption = validOptions.length > 0 ? validOptions[0] : null;

    if (!selectedOption?.desconto_cliente) {
      return [];
    }
    
    return parseDiscounts(selectedOption.desconto_cliente);
  };
};


const generateConfigOptions = (options: ConfigOption[]) => {
  const getCompanyOptionsByState = makeGeneratePowerCompaniesOptions(options);

  const getPowerLevelRangeByStateAndCompany =
    makeGenerateRangeByStateAndCompany(options);

  const getStateOptions = makeGenerateStateOptions(options);

  const getDiscountOptions = makeGenerateDiscountsByCompany(options);

  return {
    getStateOptions,
    getCompanyOptionsByState,
    getPowerLevelRangeByStateAndCompany,
    getDiscountOptions,
  };
};

export const FILE_FIELDS = [
  "caminhoarquivodoc1",
  "caminhoarquivodoc2",
  "caminhoarquivo",
  "caminhoarquivoenergia2",
  "caminhoarquivocnpj",
  "caminhocontratosocial",
  "caminhocomprovante",
  "caminhoarquivoestatutoconvencao",
];

export const FILE_NAMES_S3: Record<string, string> = {
  caminhoarquivo: "contaenergia",
  caminhoarquivoenergia2: "contaenergia2",
  caminhoarquivodoc1: "documentopessoal",
  caminhoarquivodoc2: "documentopessoalverso",
  caminhoarquivocnpj: "cartaocnpj",
  caminhocontratosocial: "contratosocial",
  caminhocomprovante: "comprovantepagamento",
  caminhoarquivoestatutoconvencao: "estatutoconvencao",
};

const getFileData = (data: string) => {
  return data?.split("base64,")[1];
};

const handleDocuments = (formData: FormData) => {
  const result: Partial<FormData> = {};

  FILE_FIELDS.forEach((key) => {
    if (formData[key]?.includes("base64,")) {
      result[key] = getFileExtensionFromBase64(getFileData(formData[key]));
    }
  });

  return result;
};

const getClassification = ({ classificacao, energyBox }: FormData) => {
  if (!classificacao && !energyBox) return {};

  return {
    classificacao: `${classificacao} - ${energyBox}`,
  };
};

const prepareFormData = (
  data: Record<string, string>,
  idconsultor: string,
  indcli: string,
  elegibility?: boolean,
  hasPlates?: boolean,
  icmsDiscount?: boolean
) => {
  const updatedData = {
    ...data,
    consumomedio:
      Number(data.consumomedio.replace(/\./g, "").replace(",", ".")) || 0,
    classificacao: `${data?.classificacao} - ${data?.energyBox}`,
    elegibilidade: elegibility || false,
    desconto_cliente: Number(data.desconto_cliente) || 0,
    caminhoarquivodoc1: getFileExtensionFromBase64(
      data.caminhoarquivodoc1?.split("base64,")[1]
    ),
    caminhoarquivodoc2: getFileExtensionFromBase64(
      data.caminhoarquivodoc2?.split("base64,")[1]
    ),
    caminhoarquivo: getFileExtensionFromBase64(
      data.caminhoarquivo?.split("base64,")[1]
    ),
    caminhoarquivoenergia2: getFileExtensionFromBase64(
      data.caminhoarquivoenergia2?.split("base64,")[1]
    ),
    caminhoarquivocnpj: getFileExtensionFromBase64(
      data.caminhoarquivocnpj?.split("base64,")[1]
    ),
    caminhocontratosocial: getFileExtensionFromBase64(
      data.caminhocontratosocial?.split("base64,")[1]
    ),
    caminhocomprovante: getFileExtensionFromBase64(
      data.caminhocomprovante?.split("base64,")[1]
    ),
    caminhoarquivoestatutoconvencao: getFileExtensionFromBase64(
      data.caminhoarquivoestatutoconvencao?.split("base64,")[1]
    ),
    idconsultor: Number(idconsultor),
    indcli: Number(indcli),
    possui_placas: hasPlates || false,
    desconto_icms: icmsDiscount || false,
  };

  return updatedData;
};

const prepareFormDataToUpdate = (
  formData: FormData,
  elegibility?: boolean,
  hasPlates?: boolean,
  icmsDiscount?: boolean
) => {
  if (!formData) return {};

  if (formData.caminhoarquivo.endsWith(".pdf") && elegibility) {
    return {
      ...formData,
      ...getClassification(formData),
      ...handleDocuments(formData),
      consumomedio:
        Number(formData.consumomedio.replace(/\./g, "").replace(",", ".")) || 0,
      desconto_cliente: Number(formData.desconto_cliente) || 0,
      elegibilidade: Boolean(elegibility),
      possui_placas: hasPlates || false,
      desconto_icms: icmsDiscount || false,
    };
  }

  return {
    ...formData,
    ...getClassification(formData),
    ...handleDocuments(formData),
    consumomedio:
      Number(formData.consumomedio.replace(/\./g, "").replace(",", ".")) || 0,
    desconto_cliente: Number(formData.desconto_cliente) || 0,
    possui_placas: hasPlates || false,
    desconto_icms: icmsDiscount || false,
  };
};

export { generateConfigOptions, prepareFormDataToUpdate, prepareFormData };
